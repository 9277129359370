@font-face {
  font-family: 'Eurostile';
  font-style: normal;
  font-weight: normal;
  src: local('Eurostile'), url('./assets/fonts/eurostile.woff') format('woff');
}
  

@font-face {
  font-family: 'EuroStyle Normal';
  font-style: normal;
  font-weight: normal;
  src: local('EuroStyle Normal'), url('./assets/fonts/EuroStyle Normal.woff') format('woff');
}

:root {
  --swiper-theme-color: #06d1bd;
  --swiper-pagination-color: #06d1bd;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.7;
  --swiper-pagination-bullet-size: 6px
}

@media (min-width: 900px) {
  :root {
    --swiper-pagination-bullet-size: 8px
  }
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  min-width: 320px;
  padding: 0;
}

/* Footer */
.footer {
  background-color: #000;
  min-height: 72px;
  width: 100%;
}

.footer__content {
  color: #fff;
  font-size: .875rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem .5rem;
}

.footer__privacy {
  display: flex;
}

.footer a,
.footer a:active,
.footer a:visited {
  color: #06d1bd;
  text-decoration-color: rgba(6, 209, 189, 0.4);
}

.footer a:hover {
  text-decoration-color: rgba(6, 209, 189, 1);
}

.footer .divider {
  padding: 0 .5rem;
}

.is-hidden-mobile {
  display: none;
}

@media (min-width: 600px) {
  .footer__content {
    flex-direction: row;
    font-size: 1rem;
    padding: 1.5rem 1rem;
  }

  .is-hidden-mobile {
    display: block;
  }
}

@media (min-width: 1200px) {
  .footer__content {
    margin: 0 auto;
    max-width: calc(1200px + 3rem);
    padding: 1.5rem;
    width: 100%;
  }
}

/* Swiper.js */
.swiper {
  display: flex;
  flex-direction: column-reverse;
  /* height: 100%; */
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  bottom: 10px;
  height: calc(100% - 10px);
  /* top: calc(50% - ((32px + .5rem) / 2)); */
  top: 0;
  width: 45px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(255, 255, 255, .05);
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination {
  align-items: center;
  display: flex;
  height: 22px;
  justify-content: center;
  position: static;
}

/* Hide last pagination bullet. Last slide is the 'complete' slide */
/* .swiper-pagination .swiper-pagination-bullet:last-of-type {
  display: none;
} */

@media (min-width: 900px) {
  .swiper-pagination {
    height: 32px;
    padding-top: .5rem;
  }
}

@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgba(6, 209, 189, 0.7);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* React Player */
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* Privacy Policy */
.privacy-policy a {
  color: #06d1bd !important;
}

.privacy-policy h1 {
  font-size: 1.5rem;
  margin: 1rem 0 2rem;
  padding: 0;
}

.privacy-policy h2 {
  font-size: 1.25rem;
  margin: 1rem 0 2rem;
  padding: 0;
}

.privacy-policy h3 {
  margin: .75rem 0 1.5rem;
}

.privacy-policy h4 {
  margin: .5rem 0 1rem;
}

.privacy-policy p {
  margin: 0 0 1rem;
}

.simple_pp {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  margin-bottom: 4rem;
  margin-top: 4rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.simple_pp ul {
  list-style-type: none;
  margin: 0;
}


.simple_pp ul li h3 {
  font-size: 1.25rem;
}

.simple_pp ul li ul {
  list-style-type: none;
  margin: 0;
}

.simple_pp ul li ul li {
  margin-top: 0;
}

.simple_pp ul li ul li h3 {
  font-size: 1rem;
}

ul.for_boxes {
  display: flex;
  flex-direction: column;
  padding: 0;
}

ul.for_boxes li .iconed {
  position: relative;
}

ul.for_boxes li .iconed.policyicon_purpose_5::before,
ul.for_boxes li .iconed.policyicon_purpose_10::before,
ul.for_boxes li .iconed.icon_owner::before {
  background-position: center;
  background-repeat: no-repeat;
  content: " ";
  display: inline-block;
  height: 40px;
  position: absolute;
  width: 32px;
}

ul.for_boxes li .iconed h3 {
  margin: 0;
  padding: 0.5rem 1rem 1rem 46px;
}

ul.for_boxes li .iconed ul,
ul.for_boxes li .iconed p {
  margin-left: 46px;
}

ul.for_boxes li .iconed ul {
  margin-top: 1.5rem;
}

ul.for_boxes li .iconed ul h3,
ul.for_boxes li .iconed ul ul,
ul.for_boxes li .iconed ul p {
  margin-left: 0;
  padding: 0;
}


.iub_footer {
  margin-top: 1rem;
}

.iub_footer:last-child {
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.iub_footer:last-child p {
  align-items: center;
  display: flex;
}
  
.iub_footer:last-child p img {
  margin-left: 1rem;
  max-height: 2rem;
}

.iub_footer:last-child .btn {
  align-self: flex-start;
  background-color: #06d1bd;
  border-color: #06d1bd;
  color: #000 !important;
  border-radius: 2rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .5rem 2rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  vertical-align: middle;
}

.iub_footer:last-child .btn:hover {
  color: #d9d9d9 !important;
  background-color: #17a478;
  border-color: #169970;
}

@media (min-width: 769px) {
  .iub_footer:last-child {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .iub_footer:last-child p {
    margin: 0;
    padding: 0;
  }
}

